/* purgecss start ignore */
@tailwind base;
@tailwind components;
/* purgecss end ignore */

@tailwind utilities;

.link {
  @apply cursor-pointer border-b border-black hover:bg-green transform duration-300 ease-in-out;
}

.dashboard-access {
  @apply relative group ring-0 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green;
}

.no-dashboard-access {
  @apply relative group transform duration-300 ease-in-out p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-green;
}

.list ul {
  @apply list-disc list-inside py-5 text-base max-w-prose space-y-1;
}

#__next {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}
