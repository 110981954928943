.graphiql-container {
  border-radius: 8px;
  height: 85vh !important;
  min-height: 500px;
  border: 1px solid rgba(24, 23, 55, 0.17);
}

.graphiql-container .CodeMirror pre,
.graphiql-container .history-contents,
.graphiql-container .CodeMirror-hint {
  font-family: "Space Mono" !important;
}

.graphiql-container .history-contents li:hover {
  background-color: #fc8088 !important;
}

.graphiql-container .topBar {
  background: #f6f6f6 !important;
}

.graphiql-container .doc-explorer-title-bar,
.graphiql-container .history-title-bar,
.graphiql-container .CodeMirror-hint-information .content {
  height: 50px !important;
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
  font-weight: normal !important;
}

.graphiql-container .topBar .execute-button-wrap {
  margin-left: 0 !important;
}

.graphiql-container .topBar .title {
  display: none;
}

.graphiql-container .topBarWrap .topBar {
  height: 50px;
}

.graphiql-container .docExplorerShow {
  background: #f6f6f6 !important;
  color: rgba(24, 23, 55) !important;
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
  font-weight: 500 !important;
}

.graphiql-container .docExplorerShow:hover {
  background: rgb(229, 231, 235) !important;
  color: rgb(81, 80, 104) !important;
}

.graphiql-container .docExplorerShow:before {
  border-color: rgba(24, 23, 55) !important;
  margin: 0 3px 2px 0 !important;
  height: 6px !important;
  width: 6px !important;
}

/* Docs explorer */
.graphiql-container .doc-explorer .doc-explorer-title {
  color: rgba(24, 23, 55);
}

.graphiql-container .doc-explorer-contents {
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
}

.graphiql-container .search-box {
  padding-bottom: 5px;
}

.graphiql-container .search-box .search-box-icon {
  margin-right: 10px;
}

.graphiql-container .search-box > input[type="text"] {
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
  border-radius: 4px !important;
  width: 88%;
}

.graphiql-container .search-box > input[type="text"]:focus {
  outline: none !important;
  border: 1px solid #fc8088 !important;
}

.graphiql-container .doc-explorer .doc-explorer-back {
  color: rgba(24, 23, 55) !important;
}

.graphiql-container .doc-explorer .doc-explorer-back::before {
  border-color: rgba(24, 23, 55) !important;
}

/* History */
.graphiql-container .history-title {
  color: rgba(24, 23, 55);
}

.graphiql-container .docExplorerHide {
  color: rgba(24, 23, 55);
}

.graphiql-container .toolbar .toolbar-button:nth-child(2) {
  display: none;
}

.graphiql-container .toolbar .toolbar-button,
.graphiql-container .execute-button-wrap .execute-button {
  background: #f6f6f6;
  box-shadow: none;
  border: 1px solid #d0d0d0;
}

.graphiql-container .toolbar .toolbar-button {
  border-radius: 4px;
  padding: 3px 10px;
  font-family: "Helvetica Now Text", Helvetica, Helvetica Neue, Arial,
    sans-serif !important;
  font-weight: 500;
}

.graphiql-container .toolbar .toolbar-button:hover,
.graphiql-container .execute-button-wrap .execute-button:hover {
  background: rgb(229, 231, 235);
}

/* Docs styling */

.graphiql-container .doc-category-item .keyword {
  color: rgba(24, 23, 55);
}

.graphiql-container .doc-category-item .type-name {
  color: #fc8088;
}

/* Code snippet in docs */
.graphiql-container .doc-category code,
.graphiql-container .doc-type-description code {
  color: #fc8088 !important;
}
